<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="teacherSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm giảng viên' : 'Cập nhật giảng viên'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="department">
          <template v-slot:label>
            Khoa/bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoa/bộ môn"
            rules="required"
          >
            <v-select
              v-model="departmentSelected"
              :options="departments"
              :reduce="option => option.value"
              @input="onChangeDepartments"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="employee">
          <template v-slot:label>
            Cán bộ <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Giảng viên"
            rules="required"
          >
            <v-select
              v-model="targetItem.employeeId"
              :options="employeeOptions"
              :reduce="option => option.value"
              :disabled="!isCreated"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Tổ bộ môn"
          label-for="teacherTeam"
        >
          <v-select
            v-model="targetItem.teamId"
            :options="teacherTeamOptions"
            :reduce="option => option.value"
          />
        </b-form-group>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetItem.status"
              :options="statuses"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('teacherSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'

export default {
  name: 'TeacherSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      employeeOptions: [],
      teacherTeamOptions: [],
      filterTeacherTeam: {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: getUser().orgId,
        departmentId: null,
        leaderId: null,
        status: 1,
      },
      filterEmployee: {
        teamId: null,
        page: 1,
        itemsPerPage: 1000,
        status: 1,
      },
      departmentSelected: null,
      isLoading: false,
      targetItem: {
        code: null,
        status: 1,
        employeeId: null,
        teamId: null,
        departmentId: null,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'teacher/statuses',
      departments: 'dropdown/departments',
      teacherTeams: 'teacherTeam/teacherTeams',
      employees: 'employee/employees',
    }),
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createTeachers: 'teacher/createTeachers',
      updateTeachers: 'teacher/updateTeachers',
      readTeacherTeams: 'teacherTeam/readTeacherTeams',
      readEmployees: 'employee/getEmployee',
    }),
    async onChangeDepartments() {
      if (this.isCreated) {
        this.targetItem.teamId = null
        this.targetItem.employeeId = null
      }

      this.filterTeacherTeam.departmentId = this.departmentSelected
      await this.readTeacherTeams(this.filterTeacherTeam)
      this.teacherTeamOptions = this.teacherTeams.filter(item => item.departmentId === this.departmentSelected).map(item => ({
        value: item.id,
        label: item.name,
      }))

      await this.readEmployees(this.filterEmployee)
      this.employeeOptions = this.employees.filter(item => item.departmentId === this.departmentSelected).map(item => ({
        value: item.id,
        label: item.fullName,
      }))
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.item) {
        // this.targetItem = {...this.item}
        this.targetItem = this.item
        this.departmentSelected = this.targetItem.departmentId
        await this.onChangeDepartments()
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        code: null,
        status: 1,
        employeeId: null,
        teamId: null,
        departmentId: null,
      }
      this.departmentSelected = null
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createTeachers(this.targetItem)
            : await this.updateTeachers(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('teacherSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
